import util from "@/util/util";
export default class Rest {
  constructor() {}

  static get({ resource, prefix = null, params = {} }) {
    return util
      .http({
        url: (prefix ? prefix + "/" : "") + resource,
        params: params,
      })
      .then((resp) => {
        if (resp.data.status == 1) {
          this[resource + "s"] = resp.data.data.data;
        }
        return resp;
      });
  }
  static getSingle({ resource, id, prefix = null }) {
    return util
      .http({
        url: (prefix ? prefix + "/" : "") + resource + "/" + id,
      })
      .then((resp) => {
        if (resp.data.status == 1) {
          this[resource] = resp.data.data;
        }
        return resp;
      });
  }
}
