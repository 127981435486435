<template lang="">
    <div class="wrapper">
        <div class="label" v-if="label">{{label}}</div>
        
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss">
$brand-primary: #dbdbdb;
.wrapper {
  border: 1px solid #bcbcbc;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  .label {
    position: absolute;
    top: -10px;
    background-color: $brand-primary;
    border: 1px solid #9e9e9e;
    padding: 6px;
    font-weight: bold;
    border-radius: 6px;
  }
}
</style>