<template>
  <div class="mt-5">
    <h4 class="text-uppercase">Tuesday, December 21</h4>
    <h2 class="text-uppercase">Good Afternoon, User</h2>
    <projectList />
  </div>
</template>

<script>
import util from "@/util/util";
import projectList from "@/components/Project/ProjectList.vue";
export default {
  components: {
    projectList,
  },
  data: () => ({
    basic: util.input.basic,

    content:
      "<h4><u>Private Notepad</u></h3> <h5>Please Enter your Note here</h5>",
    projects: [],
  }),
};
</script>

